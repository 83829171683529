/* eslint-disable deprecation/deprecation */
/* eslint-disable no-nested-ternary */
import {
  dateToTimestamp,
  GeneralMeetingType,
  GM,
  HabitatStatistics,
  InvestorStatistics,
  Organization,
  Poll,
  PollAndVoteCount,
  PollId,
  Proposal,
  ProposalId,
  ProposalOutcome,
  ProposalOutcomeVotes,
  VotingOption,
} from '@tumelo/shared'
import add from 'date-fns/add'
import { APIServiceBaseClass } from '../../../utils/api'
import {
  GetHabitatStatisticsResponse,
  GetInvestorStatisticsResponse,
  Organization as ApiOrganization,
  Poll as ApiPoll,
  Proposal as ApiProposal,
  ProposalOutcome as ApiProposalOutcome,
  ProposalOutcomeDecisionEnum,
  ProposalVotingOptionsEnum as ApiVotingOption,
} from '../../../utils/api/gen'
import { getAndExpandPagedItemsViaPageToken } from '../utils'
import { OrganizationServiceAPI } from '../Organization/OrganizationServiceAPI'
import { fromDto } from '../Voting/VotingServiceApi'
import { PollService } from './PollService'

export class PollServiceAPI extends APIServiceBaseClass implements PollService {
  async listAllPolls(): Promise<{ polls: Poll[]; organizations: Organization[] }> {
    const api = await this.getVotingApi()
    const { habitatId } = this
    const getPageFn = (token: string | undefined) =>
      api.listPollsInHabitat({ habitatId, nextPageToken: token, pageSize: 100 })
    const polls = await getAndExpandPagedItemsViaPageToken(getPageFn, (x) => x.polls)
    // extract all the organizations and only return unique ones
    const organizations = PollServiceAPI.getOrgsFromPolls(polls)
    return { polls: polls.map(PollServiceAPI.mapApiPollToPoll), organizations }
  }

  async fetchRecentPolls(): Promise<{ polls: Poll[]; organizations: Organization[] }> {
    const api = await this.getVotingApi()
    const { habitatId } = this
    const closeTimeAfter = add(new Date(), { days: -10 })
    const getPageFn = (token: string | undefined) =>
      api.listPollsInHabitat({ habitatId, nextPageToken: token, pageSize: 100, closeTimeAfter })
    const polls = await getAndExpandPagedItemsViaPageToken(getPageFn, (x) => x.polls)
    const organizations = PollServiceAPI.getOrgsFromPolls(polls)
    // extract all the organizations and only return unique ones

    return { polls: polls.map(PollServiceAPI.mapApiPollToPoll), organizations }
  }

  // TODO This isn't a list this is fetch, as you are filtering for orgs but it's more than that.
  async listOpenPollsForOrganizations(organizationIds: string[]): Promise<Map<string, Poll[]>> {
    if (organizationIds.length === 0) {
      return new Map<string, Poll[]>()
    }
    const api = await this.getVotingApi()
    const { habitatId } = this
    const getPageFn = (token: string | undefined) =>
      api.listPollsInHabitat({
        habitatId,
        organizationIds,
        nextPageToken: token,
        pageSize: 100,
        closeTimeAfter: new Date(),
      })
    const polls = await getAndExpandPagedItemsViaPageToken(getPageFn, (x) => x.polls)
    return organizationIds.reduce(
      (map, id) =>
        map.set(
          id,
          polls.filter((p) => p.relationships.organization.id === id).map((p) => PollServiceAPI.mapApiPollToPoll(p))
        ),
      new Map<string, Poll[]>()
    )
  }

  async getPoll(pollId: PollId) {
    const api = await this.getVotingApi()
    const { habitatId } = this
    const { poll } = await api.getPollInHabitat({ habitatId, pollId })
    return {
      poll: PollServiceAPI.mapApiPollToPoll(poll),
      organization: OrganizationServiceAPI.organizationApiToOrganization(poll.relationships.organization),
    }
  }

  async getInvestorOpenVotesCloseToExpiration() {
    const client = await this.getVotingApi()
    const today = new Date()
    const fiveDaysLater = add(today, {
      days: 5,
    })
    const { habitatId, investorId } = this
    const { ballots: closeToExpirationBallots } = await client.listInvestorBallots({
      habitatId,
      investorId,
      hasVoted: false,
      expireAfter: today,
      expireBefore: fiveDaysLater,
      pageSize: 2,
    })

    const { ballots: otherBallots } = await client.listInvestorBallots({
      habitatId,
      investorId,
      hasVoted: false,
      expireAfter: fiveDaysLater,
      pageSize: 2,
    })

    const ballots = [...closeToExpirationBallots, ...otherBallots].filter((x) => x !== undefined).slice(0, 2)

    const polls = await Promise.all(
      ballots.map(async (ballot) => {
        const { poll } = await client.getPollInHabitat({ habitatId, pollId: ballot.pollId })
        return poll
      })
    )

    return polls.map((poll, i) => ({
      poll: PollServiceAPI.mapApiPollToPoll(poll),
      ballot: fromDto(ballots[i]),
      organization: OrganizationServiceAPI.organizationApiToOrganization(poll.relationships.organization),
    }))
  }

  async getInvestorStatistics(): Promise<InvestorStatistics> {
    const { habitatId, investorId } = this

    const api = await this.getInvestorsApi()

    const investorStatistics: GetInvestorStatisticsResponse = await api.getInvestorStatistics({
      habitatId,
      investorId,
    })

    return PollServiceAPI.mapAPIInvestorStatisticsToDomain(investorStatistics)
  }

  async getHabitatStatistics(): Promise<HabitatStatistics> {
    const { habitatId } = this

    const api = await this.getHabitatsApi()

    const habitatStatistics: GetHabitatStatisticsResponse = await api.getHabitatStatistics({
      habitatId,
    })

    return PollServiceAPI.mapAPIHabitatStatisticsToDomain(habitatStatistics)
  }

  static mapAPIInvestorStatisticsToDomain = (resp: GetInvestorStatisticsResponse): InvestorStatistics => ({
    pollTagsVoteCount: resp.pollTagsVoteCount.map((x) => ({
      tag: { title: x.tagName, id: x.tagId.toString() },
      voteCount: x.voteCount,
    })),
  })

  static mapAPIHabitatStatisticsToDomain = (resp: GetHabitatStatisticsResponse): HabitatStatistics => {
    const mostVotedOnOpenPoll: PollAndVoteCount | undefined = resp.mostVotedOnOpenPoll && {
      ...resp.mostVotedOnOpenPoll,
      pollId: resp.mostVotedOnOpenPoll.pollName.substr('polls/'.length) as PollId,
    }

    return { mostVotedOnOpenPoll }
  }

  static mapApiPollToPoll(poll: ApiPoll): Poll {
    const { relationships, tags, title, id, formattedDescription, closeAt } = poll
    const { proposal, generalMeeting } = relationships
    if (!proposal) {
      throw new Error(`no proposal in poll ${id}`)
    }
    if (!generalMeeting) {
      throw new Error(`no general meeting poll ${id}`)
    }

    return {
      id: id as PollId,
      title,
      formattedDescription,
      endDate: dateToTimestamp(closeAt),
      tags,
      tally: {
        forCount: poll.tally.forCount,
        againstCount: poll.tally.againstCount,
        abstainCount: poll.tally.abstainCount,
        withholdCount: poll.tally.withholdCount,
        noActionCount: poll.tally.noActionCount,
      },
      isHotTopic: poll.isHotTopic,
      teaserText: poll.teaserText,
      teaserImage: poll.teaserImage && { url: poll.teaserImage?.uRL, altText: poll.teaserImage?.altText },
      publishAt: dateToTimestamp(poll.publishAt),
      isNewToPlatform: poll.isNewToPlatform,
      relationships: {
        organization: OrganizationServiceAPI.organizationApiToOrganization(poll.relationships.organization),
        generalMeeting: PollServiceAPI.mapApiPollToGM(poll),
        proposal: PollServiceAPI.mapApiProposalToProposal(proposal),
      },
    }
  }

  static mapApiProposalToProposal({ title, formattedDescription, outcome, id, votingOptions }: ApiProposal): Proposal {
    return {
      id: id as ProposalId,
      title,
      formattedDescription,
      outcome: PollServiceAPI.mapApiProposalOutcomeToOutcome(outcome),
      votingOptions: PollServiceAPI.mapApiVotingOptionsToVotingOptions(votingOptions),
    }
  }

  static mapApiVotingOptionsToVotingOptions(votingOptions: ApiVotingOption[] | undefined): VotingOption[] | undefined {
    if (votingOptions === undefined) {
      return undefined
    }
    return votingOptions.map((option) => {
      return {
        [ApiVotingOption.For]: VotingOption.For,
        [ApiVotingOption.Against]: VotingOption.Against,
        [ApiVotingOption.Abstain]: VotingOption.Abstain,
        [ApiVotingOption.Withhold]: VotingOption.Withhold,
        [ApiVotingOption.NoAction]: VotingOption.NoAction,
      }[option]
    })
  }

  static mapApiProposalOutcomeToOutcome(outcome: ApiProposalOutcome | undefined): ProposalOutcome | undefined {
    if (!outcome) {
      return undefined
    }
    let votes: ProposalOutcomeVotes | undefined
    if (outcome?.votes) {
      votes = {}
      // determined by code generation library due to for being reserved word
      votes.for = outcome.votes._for // eslint-disable-line
      const { abstain, against, brokerNonVote, withheld } = outcome.votes
      votes = { ...votes, abstain, against, brokerNonVote, withheld }
    }
    return {
      decision:
        outcome.decision === ProposalOutcomeDecisionEnum.For
          ? 'for'
          : outcome.decision === ProposalOutcomeDecisionEnum.Against
            ? 'against'
            : undefined,
      votes,
      createTime: outcome.createTime ? dateToTimestamp(outcome.createTime) : undefined,
    }
  }

  static mapApiPollToGM({ id, relationships }: ApiPoll): GM {
    const { generalMeeting, organization } = relationships
    if (!generalMeeting) {
      throw new Error(`could find gm in poll ${id}`)
    }
    const { date, generalMeetingType, proxyNoticeUrl, announcementUrl } = generalMeeting
    if (!organization) {
      throw new Error(`could find organization in poll ${id}`)
    }
    return {
      organizationId: organization.id,
      date: dateToTimestamp(date),
      proxyNoticeUrl: proxyNoticeUrl || announcementUrl,
      type: GeneralMeetingType[generalMeetingType],
    }
  }

  static getOrgsFromPolls(polls: ApiPoll[]): Organization[] {
    const seenOrgs = new Set()
    const organizations = polls
      .map((poll) => poll.relationships.organization)
      .reduce((acc, organization) => {
        if (organization) {
          return acc.concat(organization)
        }
        return acc
      }, new Array<ApiOrganization>())
      .filter((organization) => !!organization)
      .filter(({ id }: ApiOrganization) => {
        if (seenOrgs.has(id)) {
          return false
        }
        seenOrgs.add(id)
        return true
      }, [])
      .map(OrganizationServiceAPI.organizationApiToOrganization)
    return organizations
  }
}
